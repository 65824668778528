import * as React from 'react'

import '../scss/index.scss'

const NotFoundPage = function() {
    return (
        <div className="not-found">
            Siden ble ikke funnet.
        </div>
    )
}

export default NotFoundPage
